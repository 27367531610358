<template>
  <div>
    <b-modal
      id="add-channel"
      ok-only
      centered
      :ok-title="$t('Pages.Channel.Button.add_new_channel')"
      class="d-flex justify-content-center"
      @ok="add"
    >
      <div class="text-center">
        <b-img
          class="w-50 text-center mx-auto"
          :src="require('@/assets/images/channels/blocked.svg')"
        />
      </div>
      <h2 class="text-center mt-1">
        {{ $t("Pages.Channel.Title.need_access") }}
      </h2>
      <h5 class="text-center mt-1">
        {{ $t("Pages.Channel.Title.first_step_grant") }}
      </h5>
    </b-modal>
    <b-row>
      <b-col lg="12" sm="12" class="mb-2" v-if="show_alert_permission">
        <b-alert show dismissible fade class="mb-0" variant="warning">
          <div class="alert-body">
            <span>{{ $t("Pages.Channel.Title.use_all_services") }}</span>
          </div>
        </b-alert>
      </b-col>
      <b-col lg="12" sm="12" class="mb-2" v-if="show_alert">
        <b-alert show dismissible fade class="mb-0" variant="warning">
          <div class="alert-body">
            <span>{{ $t("Pages.Channel.Title.add_deactivated") }}</span>
          </div>
        </b-alert>
      </b-col>
      <b-col lg="12" sm="12" class="mb-2">
        <b-alert show dismissible fade class="mb-0" variant="info">
          <div class="alert-body">
            <p class="font-weight-bold">
              {{ $t("Pages.Channel.Title.notable_point") }}
            </p>
            <ul>
              <li>{{ $t("Pages.Channel.Title.to_receive_channel_info") }}</li>
              <li>{{ $t("Pages.Channel.Title.info_updated_24") }}</li>
            </ul>
          </div>
        </b-alert>
      </b-col>
      <b-col lg="12" sm="12">
        <b-overlay
          :show="false"
          spinner-variant="primary"
          variant="transparent"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-card no-body class="card-statistics">
            <b-card-body class="statistics-body">
              <b-row>
                <b-col xl="3" sm="3">
                  <b-media
                    no-body
                    v-b-tooltip.hover.top="
                      $t('Pages.Channel.Title.count_channel_tooltip')
                    "
                  >
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-primary">
                        <feather-icon size="24" icon="GlobeIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4
                        class="font-weight-bolder mb-0"
                        v-if="statistics.count_channel"
                      >
                        {{ statistics.count_channel }}
                      </h4>
                      <h4 class="font-weight-bolder mb-0" v-else>0</h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t("Pages.Channel.Title.count_channel") }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col xl="3" sm="3">
                  <b-media
                    no-body
                    v-b-tooltip.hover.top="
                      $t('Pages.Channel.Title.channel_permission')
                    "
                  >
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-info">
                        <feather-icon size="24" icon="ThumbsUpIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4
                        class="font-weight-bolder mb-0"
                        v-if="statistics.count_channel_full_permission"
                      >
                        {{ statistics.count_channel_full_permission }}
                      </h4>
                      <h4 class="font-weight-bolder mb-0" v-else>0</h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t("Pages.Channel.Title.full_permission") }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col xl="3" sm="3">
                  <b-media
                    no-body
                    v-b-tooltip.hover.top="
                      $t('Pages.Channel.Title.not_full_access')
                    "
                  >
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-danger">
                        <feather-icon size="24" icon="CloudOffIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4
                        class="font-weight-bolder mb-0"
                        v-if="statistics.count_channel_in_active"
                      >
                        {{ statistics.count_channel_in_active }}
                      </h4>
                      <h4 class="font-weight-bolder mb-0" v-else>0</h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t("Pages.Channel.Title.count_inactive") }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>

                <b-col xl="3" sm="3">
                  <b-media
                    no-body
                    v-b-tooltip.hover.top="
                      $t('Pages.Channel.Title.count_cooperation')
                    "
                  >
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-success">
                        <feather-icon size="24" icon="CloudIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4
                        class="font-weight-bolder mb-0"
                        v-if="statistics.count_channel_cooperation"
                      >
                        {{ statistics.count_channel_cooperation }}
                      </h4>
                      <h4 class="font-weight-bolder mb-0" v-else>0</h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t("Pages.Channel.Title.ch_co") }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-row class="d-flex justify-content-around text-center">
          <b-col lg="12" sm="12">
            <b-card class="col-12">
              <b-card-body>
                <b-row>
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      @keyup="getList"
                      v-model="filters.q"
                      :placeholder="$t('Base.Placeholder.search_ch')"
                      class="search-product"
                    />
                    <b-input-group-append is-text>
                      <feather-icon icon="SearchIcon" class="text-muted" />
                    </b-input-group-append>
                  </b-input-group>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="12" sm="12">
        <b-card v-if="tableData" no-body class="card-company-table">
          <b-overlay
            :show="!showTableData"
            spinner-variant="primary"
            variant="transparent"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <b-table
              :items="tableData"
              responsive
              :fields="fields"
              show-empty
              :empty-text="$t('Pages.Channel.Table.no_ch_found')"
              class="mb-0"
            >
              <!-- company -->
              <template #cell(name)="data">
                <div
                  class="d-flex align-items-center"
                  v-if="!data.item.is_active || !data.item.full_permission"
                >
                  <b-avatar rounded size="32" variant="light-company">
                    <b-img
                      class="img-fluid"
                      :src="data.item.avatar"
                      alt="avatar img"
                  /></b-avatar>
                  <div class="ml-1">
                    <div class="font-weight-bolder text-primary">
                      {{ data.item.nickname }}
                    </div>
                    <div class="font-small-2 text-muted">
                      {{ data.item.country }}
                    </div>
                  </div>
                </div>
                <b-link
                  v-else
                  :to="{
                    name: 'studioDashboard',
                    params: { channel_id: data.item.channel_id },
                  }"
                >
                  <div class="d-flex align-items-center">
                    <b-avatar rounded size="32" variant="light-company">
                      <b-img
                        class="img-fluid"
                        :src="data.item.avatar"
                        alt="avatar img"
                    /></b-avatar>
                    <div class="ml-1">
                      <div class="font-weight-bolder">
                        {{ data.item.nickname }}
                      </div>
                      <div class="font-small-2 text-muted">
                        {{ data.item.country }}
                      </div>
                    </div>
                  </div>
                </b-link>
              </template>

              <template #cell(revenue)="data">
                <b-overlay
                  :show="!data.item.is_active || !data.item.full_permission"
                  variant="transparent"
                  blur="1rem"
                  rounded="sm"
                  v-b-tooltip.hover.top="
                    $t('Pages.Channel.Title.info_dilay_display')
                  "
                >
                  <div class="d-flex flex-column">
                    <span class="font-weight-bolder mb-25"
                      >${{ data.item.revenue }}</span
                    >
                  </div>
                </b-overlay>
              </template>
              <!-- views -->
              <template #cell(views)="data">
                <b-overlay
                  :show="!data.item.is_active || !data.item.full_permission"
                  variant="transparent"
                  blur="1rem"
                  rounded="sm"
                >
                  <div class="d-flex flex-column">
                    <span class="font-weight-bolder mb-25">{{
                      data.item.views
                    }}</span>
                  </div>
                </b-overlay>
              </template>

              <!-- revenue -->
              <template #cell(subscribe)="data">
                <b-overlay
                  :show="!data.item.is_active || !data.item.full_permission"
                  variant="transparent"
                  blur="1rem"
                  rounded="sm"
                >
                  <div class="d-flex flex-column">
                    <span
                      class="font-weight-bolder mr-1"
                      v-if="data.item.subscribers >= 1000"
                    >
                      <b-badge pill variant="light-success">
                        {{ data.item.subscribers }}
                      </b-badge>
                    </span>
                    <span class="font-weight-bolder mr-1" v-else>
                      <b-badge pill variant="light-danger">
                        {{ data.item.subscribers }}
                      </b-badge>
                    </span>
                  </div>
                </b-overlay>
              </template>

              <!-- watchtime -->
              <template #cell(watchtime)="data">
                <b-overlay
                  :show="!data.item.is_active || !data.item.full_permission"
                  variant="transparent"
                  blur="1rem"
                  rounded="sm"
                  v-b-tooltip.hover.top="
                    $t('Pages.Channel.Title.info_dilay_display')
                  "
                >
                  <div class="d-flex flex-column">
                    <span
                      class="font-weight-bolder mr-1"
                      v-if="data.item.watchtime >= 4000"
                    >
                      <b-badge pill variant="light-success">
                        {{ data.item.watchtime }}
                      </b-badge>
                    </span>
                    <span class="font-weight-bolder mr-1" v-else>
                      <b-badge pill variant="light-danger">
                        {{ data.item.watchtime }}
                      </b-badge>
                    </span>
                  </div>
                </b-overlay>
              </template>

              <!-- sales -->
              <template #cell(video)="data">
                <b-overlay
                  :show="!data.item.is_active || !data.item.full_permission"
                  variant="transparent"
                  blur="1rem"
                  rounded="sm"
                >
                  <div class="d-flex align-items-center">
                    <span class="font-weight-bolder mr-1">{{
                      data.item.videos
                    }}</span>
                  </div>
                </b-overlay>
              </template>

              <template #cell(actions)="data">
                <div
                  class="d-flex align-items-center"
                  v-if="!data.item.is_active || !data.item.full_permission"
                >
                  <feather-icon
                    icon="AlertTriangleIcon"
                    size="16"
                    class="mx-1 cursor-pointer"
                    v-b-modal.add-channel
                    v-b-tooltip.hover.top="$t('Pages.Channel.Title.re_add')"
                  />
                </div>
                <div class="d-flex align-items-center" v-else>
                  <feather-icon
                    icon="EyeIcon"
                    size="16"
                    class="mx-1 cursor-pointer"
                    @click="
                      $router.push({
                        name: 'studioDashboard',
                        params: { channel_id: data.item.channel_id },
                      })
                    "
                  />
                  <b-tooltip
                    :title="$t('Pages.Channel.Table.Dashboard')"
                    class="cursor-pointer"
                    :target="`invoice-row-${data.item.id}-preview-icon`"
                  />

                  <b-img
                    :src="
                      require('@/assets/images/icons/youtube_social_icon_red.png')
                    "
                    height="20px"
                    width="auto"
                    alt="Youtube"
                    class="mx-1 cursor-pointer"
                    @click="openYoutube(data.item.channel_id)"
                  />
                  <b-tooltip
                    :title="$t('Pages.Channel.Table.youtube_link')"
                    class="cursor-pointer"
                    :target="`invoice-row-${data.item.id}-youtube-icon`"
                  />
                </div>
              </template>
            </b-table>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col cols="12" v-if="pages > 1">
        <b-pagination-nav
          :link-gen="linkGen"
          :number-of-pages="pages"
          use-router
          class="mb-0"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BAvatar,
  BTable,
  BImg,
  BLink,
  BButton,
  BAlert,
  BBadge,
  BTooltip,
  BPaginationNav,
  BFormGroup,
  BFormInput,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
  BTabs,
  BTab,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardBody,
  BCardHeader,
  BCardSubTitle,
  BCardTitle,
  BProgress,
  BModal,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BTable,
    BImg,
    BLink,
    BButton,
    BAlert,
    BBadge,
    BTooltip,
    BPaginationNav,
    BFormGroup,
    BFormInput,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
    BTabs,
    BTab,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardBody,
    BCardHeader,
    BCardSubTitle,
    BCardTitle,
    BProgress,
    BModal,
    VBTooltip,
  },
  data() {
    return {
      tableData: [],
      fields: [
        { key: "name", label: this.$t("Pages.Channel.Table.channel_name") },
        {
          key: "revenue",
          label: this.$t("Pages.Channel.Table.Income"),
          headerTitle: this.$t("Pages.Channel.Title.info_dilay_display"),
        },
        { key: "views", label: this.$t("Pages.Channel.Table.View") },
        { key: "subscribe", label: this.$t("Pages.Channel.Table.Subscriber") },
        {
          key: "watchtime",
          label: this.$t("Pages.Channel.Table.Watch Time"),
          headerTitle: this.$t("Pages.Channel.Title.info_dilay_display"),
        },
        { key: "video", label: this.$t("Pages.Channel.Table.Videos") },
        { key: "actions", label: this.$t("Pages.Channel.Table.Operation") },
      ],
      showTableData: false,
      show_alert: false,
      show_alert_permission: false,
      filters: { q: "" },
      pages: 0,
      statistics: {},
      PermissionAddChannel: false,
      count_channels: 1,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    this.getUserInfo();
    this.getStatistics();
    this.getList();
    this.$http.get("/api/check_login").then(({ data }) => {
      let permissions = [];
      if (data.permissions.length != 0) {
        data.permissions.map((item) => {
          permissions.push(item.name);
        });
      }
      if (permissions.includes("Add Channel")) {
        this.PermissionAddChannel = true;
        this.count_channels = data.permissions.find(
          (e) => e.name == "Add Channel"
        ).meta.count
          ? parseInt(
              data.permissions.find((e) => e.name == "Add Channel").meta.count
            )
          : 1;
      }
    });
  },
  watch: {
    $route(to, from) {
      if (to.query != from.query) {
        this.getList();
      }
    },
  },
  methods: {
    getUserInfo() {
      this.$http.get("/api/dashboard/getUserPlan").then((response) => {
        this.plan = response.data;
      });
    },
    openYoutube(channelId) {
      window.open(`https://www.youtube.com/channel/${channelId}`, "_blank");
    },
    getList() {
      let filter = this.filters.q;
      if (this.filters.q == "") {
        filter = "";
      }
      let page =
        typeof this.$route.query.page !== "undefined"
          ? this.$route.query.page
          : 1;
      this.$http
        .get(`/api/channel/list?filter=${filter}&page=${page}`)
        .then((response) => {
          this.tableData = response.data.list_channels.data;
          this.pages = response.data.list_channels.last_page;
          this.show_alert = response.data.show_alert;
          this.show_alert_permission = response.data.show_alert_permission;
          this.showTableData = true;
        });
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    getStatistics() {
      this.$http.get(`/api/channel/statics`).then((response) => {
        this.statistics = response.data;
      });
    },
    add() {
      this.$http.get("/api/user/setting/check-verify").then((response) => {
        if (response.data && response.data.status) {
          if (this.tableData.length < this.count_channels) {
            this.$http
              .get("/api/channel/add")
              .then((response) => {
                if (response.status === 206) {
                  this.makeToast(
                    this.$t("Base.Alert.Error"),
                    this.$t("Navbar.Title.verify_account"),
                    "danger"
                  );
                } else {
                  window.location.href = response.data;
                }
              })
              .catch((error) => {
                if (error.response.status === 401) {
                  this.$VueCookies.remove("mastertube");
                  this.$router.push("/login").catch(() => {});
                }
              });
          } else {
            this.$swal({
              title: this.$t("Base.Alert.Error"),
              text: this.$t("Navbar.Title.limit_add"),
              icon: "warning",
              confirmButtonText: this.$t("Navbar.Button.upgrade_account"),
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.$router.push("/pricing").catch(() => {});
              }
            });
          }
        } else {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Base.Alert.verify_email_phone"),
            "danger"
          );
        }
      });
    },
  },
};
</script>
<style>
.modal-backdrop {
  opacity: 0.7 !important;
}

[dir] .spinner-border {
  border: 0px !important;
}
</style>
